$primary: #3e4860;
$primary-lightened: #515a70;
$secondary: #7cbeff;
$link: #0a6cbd;
$text: #2196f3;
$transition: .2s ease-in-out;
$transition-eo: .2s ease-out;

// Breakpoints
$bp-lg: 32rem;
$bp-md: 24rem;
$bp-sm: 19.25rem;
